
.right_nav{
    float: left;
}
#navbarNav{
    float: right;

}
.nav-item {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-decoration: none;
  color: #000000;
}
.nav-link {
  color: #000000;
}
.nav-link:hover{
    color: #ECE7EB; 
}
