.projects-slide-one{
  width: 130px;
  height: 46px;
  border: 2px solid black ;  
  border-radius: 5px;
  margin-left: 20%;
  margin-top: 30%;
  
  /* box-shadow: 6px -5px 0px  inset  ; */

}
.projects-slide-one:hover{
    transition: .5s;
    box-shadow: 6px -5px 0px  inset  ;

}
.projects-slide-one-container:hover .left-btn{
    display: block;
}
.left-btn{
    margin-left: 10%;
    margin-top: 30%;
    display: none;

}